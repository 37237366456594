import React from "react"
import * as seadn from "@opensea/seadn"
import { useFragment } from "react-relay"
import styled, { css } from "styled-components"
import { MuxVideo } from "@/components/common/MuxVideo"
import { HeroMedia_data$key } from "@/lib/graphql/__generated__/HeroMedia_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { PrimaryDropsBannerImage } from "../../../PrimaryDropsBannerImage"

type HeroMediaProps = {
  dataKey: HeroMedia_data$key | null
  isMobile?: boolean
  title: string
}

export const HeroMedia = ({ dataKey, isMobile, title }: HeroMediaProps) => {
  const data = useFragment(
    graphql`
      fragment HeroMedia_data on MediaType {
        ...PrimaryDropsBannerImage_data
        ... on ImageMediaType {
          mediaType
        }
        ... on VideoMediaType {
          aspectRatio
          mediaType
          thumbnailUrl
          videoUrl
        }
        ... on MuxVideoMediaType {
          aspectRatio
          mediaType
          thumbnailUrl
          playbackId
        }
      }
    `,
    dataKey,
  )

  if (!data) {
    return null
  }

  // We have videos in mobileHeroMedia that are 16:9 aspect ratio from before we supported separate videos,
  // we should continue rendering these at 16:9
  const renderMobileVideo = isMobile && data.aspectRatio === "4:5"
  const thumbnailUrl =
    data.thumbnailUrl &&
    seadn.resizeImage(data.thumbnailUrl, {
      width: renderMobileVideo ? 1200 : 2560,
    })

  return (
    <>
      <PrimaryDropsBannerImage dataKey={data} />
      {data.mediaType && ["MUX_VIDEO", "VIDEO"].includes(data.mediaType) && (
        <>
          <BlurOverlay />
          <VideoContainer>
            {data.mediaType === "VIDEO" ? (
              <StyledVideo
                $isMobile={renderMobileVideo}
                autoPlay
                controls={false}
                loop
                muted
                playsInline
                poster={thumbnailUrl}
              >
                <source src={data.videoUrl} type="video/mp4" />
              </StyledVideo>
            ) : (
              data.playbackId && (
                <StyledMuxPlayer
                  $isMobile={renderMobileVideo}
                  autoPlay="muted"
                  key={data.playbackId}
                  muted
                  playbackId={data.playbackId}
                  playsInline
                  poster={thumbnailUrl}
                  title={title}
                />
              )
            )}
          </VideoContainer>
        </>
      )}
    </>
  )
}

const BlurOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(20px);
  background-color: rgba(0, 0, 0, 0.6);
`

const absoluteCenteredCss = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const coverVideoDimensionsCss = css<{ $isMobile?: boolean }>`
  ${absoluteCenteredCss};
  height: 100%;
  min-width: 100%;
  padding-top: 0;

  ${({ $isMobile }) =>
    $isMobile
      ? css`
          /* 4:5 */
          width: 80vh; /* 100 * 4 / 5 */
          min-height: 125vw; /* 100 * 5 / 4 */
        `
      : css`
          /* 16:9 */
          width: 177.77777778vh; /* 100 * 16 / 9 */
          min-height: 56.25vw; /*100 * 9 / 16 */
        `}
`

const VideoContainer = styled.div`
  ${absoluteCenteredCss};
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const StyledVideo = styled.video<{ $isMobile?: boolean }>`
  ${coverVideoDimensionsCss};
  max-width: none;
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const StyledMuxPlayer = styled(MuxVideo)<{ $isMobile?: boolean }>`
  &&& {
    max-width: none;
    ${coverVideoDimensionsCss};

    mux-player {
      --controls: none;
      ${coverVideoDimensionsCss};
      min-height: 100%;
    }
  }
`
