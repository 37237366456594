import React from "react"
import { Media } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { PrimaryDropHeroMedia_data$key } from "@/lib/graphql/__generated__/PrimaryDropHeroMedia_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { HeroMedia } from "./components/HeroMedia"

export const PrimaryDropHeroMedia = ({
  dataKey,
  title,
}: {
  title: string
  dataKey: PrimaryDropHeroMedia_data$key | null
}) => {
  const data = useFragment<PrimaryDropHeroMedia_data$key>(
    graphql`
      fragment PrimaryDropHeroMedia_data on HeroMetadataType {
        desktopHeroMedia {
          ...HeroMedia_data
        }
        mobileHeroMedia {
          ...HeroMedia_data
        }
      }
    `,
    dataKey,
  )

  return (
    <>
      <Media lessThan="md">
        <HeroMedia
          dataKey={data?.mobileHeroMedia ?? null}
          isMobile
          title={title}
        />
      </Media>
      <Media greaterThanOrEqual="md">
        <HeroMedia dataKey={data?.desktopHeroMedia ?? null} title={title} />
      </Media>
    </>
  )
}
