import React from "react"
import { useFragment } from "react-relay"
import styled from "styled-components"
import { CountdownCellContainerHeight } from "@/components/common/CountdownTimer/components/CountdownTimerCell/CountdownTimerCell.react"
import { Block } from "@/design-system/Block"
import { LivePulse } from "@/design-system/LivePulse"
import { LIVE_PULSE_VARIANT } from "@/design-system/LivePulse/LivePulse.react"
import {
  DROP_STATES,
  useDropState,
} from "@/features/primary-drops/hooks/useDropState"
import { blurClearButton } from "@/features/primary-drops/styleUtils"
import { useTranslate } from "@/hooks/useTranslate"
import { HeroStageText_data$key } from "@/lib/graphql/__generated__/HeroStageText_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { UnreachableCaseError } from "@/lib/helpers/type"
import { useDropStages } from "../../hooks/useDropStages"
import { ResponsiveText } from "../ResponsiveText"
import { StageStartCountdown } from "../StageStartCountdown"

type HeroStageTextProps = {
  dataKey: HeroStageText_data$key | null
}

export const HeroStageText = ({ dataKey }: HeroStageTextProps) => {
  const t = useTranslate("drop")
  const data = useFragment(
    graphql`
      fragment HeroStageText_data on CollectionType {
        ...useDropState_data
        dropv2 {
          stages {
            ...useDropStages
            ...StageStartCountdown_stage
          }
        }
      }
    `,
    dataKey,
  )

  const dropState = useDropState(data)
  const { ctaStage } = useDropStages(data?.dropv2?.stages ?? [])

  if (!ctaStage) {
    return null
  }

  const getContent = () => {
    switch (dropState) {
      case DROP_STATES.ENDED:
        return (
          <SubheadingText>
            <LivePulse variant={LIVE_PULSE_VARIANT.INACTIVE} />
            {t("heroStageText.dropEnded", "Mint ended")}
          </SubheadingText>
        )
      case DROP_STATES.MINTED_OUT:
        return (
          <SubheadingText>
            <LivePulse variant={LIVE_PULSE_VARIANT.INACTIVE} />
            {t("heroStageText.dropSoldOut", "Mint sold out")}
          </SubheadingText>
        )
      case DROP_STATES.ACTIVE:
        return (
          <SubheadingText>
            <LivePulse variant={LIVE_PULSE_VARIANT.LIVE} />
            {t("heroStageText.dropActive", "Minting now")}
          </SubheadingText>
        )
      case DROP_STATES.UPCOMING:
        return <StageStartCountdown dataKey={ctaStage} translucent />
    }
    throw new UnreachableCaseError(dropState as never)
  }

  return <Block>{getContent()}</Block>
}

const SubheadingText = styled(ResponsiveText.Body).attrs({
  size: "medium",
  weight: "semibold",
  color: "white",
})`
  ${CountdownCellContainerHeight}
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  --webkit-font-smoothing: antialiased;
  ${blurClearButton}
`
