/**
 * @generated SignedSource<<7ce7acf249e265f094786ba4b1f99acd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeroStageText_data$data = {
  readonly dropv2: {
    readonly stages: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"StageStartCountdown_stage" | "useDropStages">;
    }>;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"useDropState_data">;
  readonly " $fragmentType": "HeroStageText_data";
};
export type HeroStageText_data$key = {
  readonly " $data"?: HeroStageText_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeroStageText_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeroStageText_data",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useDropState_data"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "dropv2",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "stages",
          "plural": true,
          "selections": [
            {
              "kind": "InlineDataFragmentSpread",
              "name": "useDropStages",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startTime",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endTime",
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "StageStartCountdown_stage"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};

(node as any).hash = "5819f8e93e2f160fb86825ba09031ec2";

export default node;
