/**
 * @generated SignedSource<<9863b325ea558ce66e1154988da6d24b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PrimaryDropHeroMedia_data$data = {
  readonly desktopHeroMedia: {
    readonly " $fragmentSpreads": FragmentRefs<"HeroMedia_data">;
  };
  readonly mobileHeroMedia: {
    readonly " $fragmentSpreads": FragmentRefs<"HeroMedia_data">;
  };
  readonly " $fragmentType": "PrimaryDropHeroMedia_data";
};
export type PrimaryDropHeroMedia_data$key = {
  readonly " $data"?: PrimaryDropHeroMedia_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"PrimaryDropHeroMedia_data">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "HeroMedia_data"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PrimaryDropHeroMedia_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "desktopHeroMedia",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "mobileHeroMedia",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "HeroMetadataType",
  "abstractKey": null
};
})();

(node as any).hash = "97ca11872848a9a16136fb8828fb64fd";

export default node;
