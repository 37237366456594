import React from "react"
import { useFragment } from "react-relay"
import { Image } from "@/design-system/Image"
import { PrimaryDropsBannerImage_data$key } from "@/lib/graphql/__generated__/PrimaryDropsBannerImage_data.graphql"
import { graphql } from "@/lib/graphql/graphql"

type PrimaryDropsBannerImageProps = {
  dataKey: PrimaryDropsBannerImage_data$key | null
}

export const PrimaryDropsBannerImage = ({
  dataKey,
}: PrimaryDropsBannerImageProps) => {
  const data = useFragment(
    graphql`
      fragment PrimaryDropsBannerImage_data on MediaType {
        ... on ImageMediaType {
          mediaType
          imageUrl
        }
        ... on VideoMediaType {
          mediaType
          thumbnailUrl
        }
        ... on MuxVideoMediaType {
          mediaType
          thumbnailUrl
        }
      }
    `,
    dataKey,
  )

  const url = data?.mediaType === "IMAGE" ? data.imageUrl : data?.thumbnailUrl

  return (
    <Image
      alt=""
      layout="fill"
      objectFit="cover"
      objectPosition="center"
      priority
      src={url ?? ""}
    />
  )
}
