import React from "react"
import { CenterAligned, FlexColumn, Icon, Skeleton } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import styled, { css } from "styled-components"
import { AccountLink } from "@/components/accounts/AccountLink.react"
import { Link } from "@/components/common/Link"
import { VerifiedIcon } from "@/components/common/VerifiedIcon.react"
import { Block } from "@/design-system/Block"
import { Flex } from "@/design-system/Flex"
import { Image } from "@/design-system/Image"
import {
  isDropStateEnded,
  useDropState,
} from "@/features/primary-drops/hooks/useDropState"
import { usePrimaryDropContext } from "@/features/primary-drops/hooks/usePrimaryDropContext"
import { useShouldShowReminder } from "@/features/primary-drops/hooks/useShouldShowReminder"
import { useDropCollectionSlugRewrite } from "@/hooks/useFlag"
import { useTranslate } from "@/hooks/useTranslate"
import { Banner_data$key } from "@/lib/graphql/__generated__/Banner_data.graphql"
import {
  getCollectionUrl,
  getCollectionOverviewUrl,
} from "@/lib/helpers/collection"
import { getSocialIcon } from "@/lib/helpers/icons"
import { media } from "@/styles/styleUtils"
import {
  blurClearButton,
  linkParentCss,
  nonInteractiveOverlay,
} from "../../styleUtils"
import { HeroStageText } from "../HeroStageText"
import { PrimaryDropHeroMedia } from "../PrimaryDropHeroMedia"
import { ReminderSignup } from "../ReminderSignup"
import { ResponsiveText } from "../ResponsiveText"

export type BannerProps = {
  dataKey: Banner_data$key | null
  eventSource: string
  disableZoomOnHover?: boolean
  redirectToCollectionOnDropEnd?: boolean
}

export const Banner = ({
  dataKey,
  eventSource,
  disableZoomOnHover,
  redirectToCollectionOnDropEnd,
}: BannerProps) => {
  const t = useTranslate("drop")
  const data = useFragment<Banner_data$key>(
    graphql`
      fragment Banner_data on CollectionType {
        slug
        ...HeroStageText_data
        ...collection_url
        externalUrl
        ...useDropState_data
        metadata {
          custom {
            isMockDrop
            hideProgressBar
          }
        }
        ...useShouldShowReminder_data
        dropv2 {
          landingPageReady
          name
          ... on Drop721LimitedEditionType {
            totalItemCount
          }
          ... on Drop1155LimitedEditionType {
            totalItemCount
          }
          stages {
            stageType
            ... on DropStage721PublicLinearPricingType {
              __typename
              priceType {
                unit
                symbol
              }
            }
            ... on DropStage721PresaleLinearPricingType {
              __typename
              priceType {
                unit
                symbol
              }
            }
            ... on DropStage1155PublicLinearPricingType {
              __typename
              mintOptions {
                priceType {
                  unit
                  symbol
                }
              }
            }
            ... on DropStage1155PresaleLinearPricingType {
              __typename
              mintOptions {
                priceType {
                  unit
                  symbol
                }
              }
            }
          }
        }
        verificationStatus
        owner {
          config
          displayName
          ...AccountLink_data
        }
        metadata {
          custom {
            overrideName
          }
          hero {
            ...PrimaryDropHeroMedia_data
            partnerLogoUrl
          }
        }
      }
    `,
    dataKey,
  )

  const landingPageReady = data?.dropv2?.landingPageReady

  const showReminderButton = useShouldShowReminder(data ?? null)
  const { isOpenEdition } = usePrimaryDropContext()
  const slugRewrites = useDropCollectionSlugRewrite()
  const dropState = useDropState(data)

  if (!data?.dropv2) {
    return null
  }
  const isMockDrop = data.metadata?.custom?.isMockDrop
  const overrideName = data.metadata?.custom?.overrideName

  const stages = data.dropv2.stages

  const publicStage = stages.find(stage => {
    return stage.stageType === "PUBLIC"
  })

  // If public stage is hidden, just use the first returned stage
  const stageToDisplay = publicStage ?? stages[0]

  const { dropv2: drop, verificationStatus, owner, metadata } = data

  const shouldShowCollectionLink =
    (redirectToCollectionOnDropEnd &&
      dropState &&
      isDropStateEnded(dropState)) ||
    isMockDrop
  let { priceType } = stageToDisplay
  const firstOption = stageToDisplay.mintOptions?.[0]
  if (firstOption) {
    priceType = firstOption.priceType
  }

  return (
    <Content $disableZoomOnHover={!!disableZoomOnHover}>
      <SpotlightCard>
        <MediaContainer>
          <PrimaryDropHeroMedia
            dataKey={data.metadata?.hero ?? null}
            title={t("spotlight.viewDrop.title", "home page spotlight")}
          />
        </MediaContainer>
        <OpacityLayer />
        {landingPageReady && (
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <Link
            className="absolute h-full w-full"
            eventSource={eventSource}
            href={
              shouldShowCollectionLink
                ? slugRewrites?.[data.slug]
                  ? `/collection/${slugRewrites[data.slug]}`
                  : getCollectionUrl(data)
                : getCollectionOverviewUrl(data)
            }
          />
        )}

        <FlexColumn className="pointer-events-none z-[3] w-full flex-1 justify-end xs:p-4 md:p-10">
          {metadata?.hero?.partnerLogoUrl && (
            <LogoContainer>
              <Image
                alt={owner?.displayName ?? ""}
                layout="fill"
                objectFit="contain"
                objectPosition="left"
                priority
                src={metadata.hero.partnerLogoUrl}
                width={280}
              />
            </LogoContainer>
          )}
          <Flex alignSelf="stretch" justifyContent="space-between">
            <FlexColumn className="mt-6 flex-1 items-start">
              <HeadingContainer>
                <Heading size="medium">
                  {overrideName || drop.name}
                  {verificationStatus === "VERIFIED" && (
                    <span>
                      <StyledCollectionVerificationIcon />
                    </span>
                  )}
                </Heading>
              </HeadingContainer>
              {owner && (
                <OwnerContainer alignItems="center">
                  <OwnerText size="medium" weight="semibold">
                    By&nbsp;
                    <AccountLink
                      dataKey={owner}
                      showBadge={false}
                      variant="no-image"
                    />
                  </OwnerText>
                  {owner.config === "VERIFIED" && (
                    <CenterAligned className="ml-1">
                      <StyledAccountVerificationIcon />
                    </CenterAligned>
                  )}
                </OwnerContainer>
              )}
              {!isMockDrop && (
                <DetailsContainer>
                  {landingPageReady ? (
                    <BodyText size="medium">
                      {isOpenEdition
                        ? t("banner.openEdition", "Open edition")
                        : !data.metadata?.custom?.hideProgressBar
                        ? t(
                            "banner.itemCount",
                            {
                              0: "{{mintedItemCount}} items",
                              one: "{{mintedItemCount}} item",
                              other: "{{mintedItemCount}} items",
                            },
                            {
                              count: data.dropv2.totalItemCount ?? 0,
                              mintedItemCount:
                                data.dropv2.totalItemCount?.toLocaleString() ??
                                "0",
                            },
                            { forceString: true },
                          )
                        : ""}{" "}
                      {isOpenEdition ||
                        (!data.metadata?.custom?.hideProgressBar && "·")}{" "}
                      {priceType && (
                        <>
                          {priceType.unit} {priceType.symbol}
                        </>
                      )}
                    </BodyText>
                  ) : (
                    <MoreDetailsText size="medium">
                      {data.externalUrl ? (
                        <Link
                          className="flex items-center"
                          href={data.externalUrl}
                          target="_blank"
                          variant="subtle"
                        >
                          <div className="mr-2">
                            <BodyText size="medium">
                              {t("banner.learnMore", "Learn more")}
                            </BodyText>
                          </div>

                          <Block marginTop="1px">
                            {getSocialIcon({
                              name: "website_external",
                              width: 13,
                              height: 13,
                              fill: "white",
                            })}
                          </Block>
                        </Link>
                      ) : (
                        <BodyText size="medium">
                          {t(
                            "banner.moreDetailsComingSoon",
                            "More details coming soon",
                          )}
                          {data.externalUrl && "."}
                        </BodyText>
                      )}
                    </MoreDetailsText>
                  )}
                </DetailsContainer>
              )}
              {landingPageReady && !isMockDrop && (
                <Block marginTop={24}>
                  <HeroStageText dataKey={data} />
                </Block>
              )}
            </FlexColumn>
            <Flex alignSelf="flex-end">
              {showReminderButton && (
                <ReminderSignup
                  slug={data.slug}
                  trigger={open => (
                    <SetReminderButton
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        open()
                      }}
                    >
                      <Icon value="notifications" />
                    </SetReminderButton>
                  )}
                />
              )}
              {landingPageReady && (
                <ViewDrop>
                  <ResponsiveText.Body
                    color="white"
                    size="medium"
                    weight="semibold"
                  >
                    {shouldShowCollectionLink
                      ? t(
                          "spotlight.viewDrop.buttonToCollection",
                          "View collection",
                        )
                      : t("spotlight.viewDrop.buttonToDrop", "View drop")}
                  </ResponsiveText.Body>
                </ViewDrop>
              )}
            </Flex>
          </Flex>
        </FlexColumn>
      </SpotlightCard>
    </Content>
  )
}

const MediaContainer = styled(FlexColumn)`
  height: 100%;
  width: 100%;
  position: absolute;
`

const LogoContainer = styled(Block)`
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.colors.white};
  background-color: black;
  overflow: hidden;
  ${media({
    xs: css`
      height: 48px;
      width: 48px;
      border-radius: ${props => props.theme.borderRadius.large};
    `,
    md: css`
      height: 64px;
      width: 64px;
      border-radius: ${props => props.theme.borderRadius.large};
    `,
  })}
`

const StyledCollectionVerificationIcon = styled(VerifiedIcon).attrs({
  size: "tiny",
  variant: "white",
})`
  ${media({
    xs: css`
      height: 16px;
      width: 16px;
    `,
    md: css`
      height: 24px;
      width: 24px;
      /* Due to the alignment of the text, we need to offset this icon a bit to visually center it */
      position: relative;
      top: 2px;
    `,
  })}
`

const StyledAccountVerificationIcon = styled(VerifiedIcon).attrs({
  size: "tiny",
  variant: "white",
})`
  ${media({
    xs: css`
      height: 12px;
      width: 12px;
    `,
    md: css`
      height: 14px;
      width: 14px;
    `,
  })}
`

const OpacityLayer = styled(Block)`
  ${nonInteractiveOverlay}
  background-color: rgba(0, 0, 0, 0.2);
`

export const SetReminderButton = styled(Flex)<{ noMargin?: boolean }>`
  cursor: pointer;
  pointer-events: all;
  ${blurClearButton}
  height: var(--button-height);
  width: var(--button-height);
  ${({ noMargin }) =>
    !noMargin &&
    media({
      md: css`
        margin-right: 10px;
      `,
    })}

  transition: background-color 0.2s 0s ease-in-out;
  &:hover {
    background-color: white;
    i {
      color: black;
    }
    span {
      color: ${props => props.theme.colors.charcoal};
    }
  }
  &:active {
    background-color: rgba(255, 255, 255, 0.9);
    span {
      color: ${props => props.theme.colors.charcoal};
    }
  }
`

const ViewDrop = styled(Flex)`
  min-height: 100%;
  height: var(--button-height);
  ${blurClearButton}
  ${media({
    xs: css`
      display: none;
    `,
    md: css`
      display: flex;
    `,
  })}
`

const SpotlightCard = styled(Flex)<{
  $bgImage?: string
}>`
  height: 100%;
  width: 100%;
  &:after {
    ${nonInteractiveOverlay}
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.24) 100%
    );
  }
  background: url(${({ $bgImage }) => $bgImage}) center no-repeat;
  background-size: cover;
  overflow: hidden;
`

const zoomAnimationCss = css`
  ${MediaContainer} {
    img,
    video {
      transition: transform 0.2s 0s ease-in-out;
    }
  }
  &:hover {
    ${MediaContainer} {
      img {
        transform: scale(1.03);
      }
      video {
        transform: scale(1.03) translate(-49%, -49%);
      }
    }
  }
  &:active {
    ${MediaContainer} {
      video {
        transform: scale(1.04) translate(-49%, -49%);
      }
      img {
        transform: scale(1.04);
      }
    }
  }
`

const hoverCardCss = css`
  &:hover {
    ${ViewDrop} {
      background-color: white;
      i {
        color: black;
      }
      span {
        color: ${props => props.theme.colors.charcoal};
      }
    }
  }
  &:active {
    ${ViewDrop} {
      background-color: rgba(255, 255, 255, 0.9);
      span {
        color: ${props => props.theme.colors.charcoal};
      }
    }
  }
`

const viewDropAnimationCss = css`
  ${ViewDrop} {
    transition: background-color 0.2s 0s ease-in-out;
  }
`

export const BannerSkeleton = styled(Skeleton.Line)`
  border-radius: 24px;
`

const Content = styled(FlexColumn)<{
  $disableZoomOnHover: boolean
}>`
  /*
    height = ratio * width
    width = screenWidth - (paddingLeft + paddingRight)
    so, height = ratio * (screenWidth - (paddingLeft + paddingRight))
  */
  ${props => (!props.$disableZoomOnHover ? zoomAnimationCss : "")}
  ${viewDropAnimationCss}
  ${hoverCardCss}
  ${media({
    xs: css`
      border-radius: ${props => props.theme.borderRadius.large};
      /* ratio 7/8 (8/7 = 1.14) */
      height: calc(1.14 * (100vw - 32px));
    `,
    md: css`
      /* ratio 4/3 (3/4 = 0.75) */
      height: calc(0.75 * (100vw - 32px));
    `,
    lg: css`
      /* ratio 3/1 (1/3 = 0.4) */
      height: calc(0.33 * (100vw - 64px));
    `,
    xl: css`
      border-radius: ${props => props.theme.borderRadius.large};
    `,
    xxl: css`
      /* ratio 18/5 (5/18 = 0.278) */
      height: calc(0.278 * (100vw - 128px));
    `,
  })}
  overflow: hidden;
  width: 100%;
  position: relative;
  z-index: 3;
`

const Heading = styled(ResponsiveText.Display)`
  && {
    color: white;
  }
  margin: 0;
  ${linkParentCss}

  span {
    display: inline-block;
    margin-left: 8px;
  }
`

const OwnerText = styled(ResponsiveText.Body)`
  align-items: center;
  margin: 0;
  && {
    color: white;
  }
  display: flex;
  ${linkParentCss};
`

const HeadingContainer = styled.h1`
  margin: 0;
  ${media({
    xs: css`
      width: 75%;
    `,
    lg: css`
      width: 66%;
    `,
  })}
`

const headerTextSpacing = css`
  margin-top: 4px;
  ${media({
    xxl: css`
      margin-top: 8px;
    `,
  })}
`

const BodyText = styled(ResponsiveText.Body).attrs({
  weight: "semibold",
  className: "text-white",
})`
  display: flex;
`

const MoreDetailsText = styled(BodyText)`
  flex-direction: column;
`

const DetailsContainer = styled(Flex)`
  ${headerTextSpacing}
`

const OwnerContainer = styled(Flex)`
  ${headerTextSpacing}
`
