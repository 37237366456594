/**
 * @generated SignedSource<<5c3d709a4f4ed78e593027f827e3e890>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PrimaryDropsBannerImage_data$data = {
  readonly imageUrl?: string;
  readonly mediaType?: string;
  readonly thumbnailUrl?: string;
  readonly " $fragmentType": "PrimaryDropsBannerImage_data";
};
export type PrimaryDropsBannerImage_data$key = {
  readonly " $data"?: PrimaryDropsBannerImage_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"PrimaryDropsBannerImage_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mediaType",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "thumbnailUrl",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PrimaryDropsBannerImage_data",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageUrl",
          "storageKey": null
        }
      ],
      "type": "ImageMediaType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "VideoMediaType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "MuxVideoMediaType",
      "abstractKey": null
    }
  ],
  "type": "MediaType",
  "abstractKey": "__isMediaType"
};
})();

(node as any).hash = "d4233afeffc1af3dbaf385f1dcd3480f";

export default node;
