/**
 * @generated SignedSource<<9b1df45f3c5d3b7905975bf509d26668>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeroMedia_data$data = {
  readonly aspectRatio?: string;
  readonly mediaType?: string;
  readonly playbackId?: string;
  readonly thumbnailUrl?: string;
  readonly videoUrl?: string;
  readonly " $fragmentSpreads": FragmentRefs<"PrimaryDropsBannerImage_data">;
  readonly " $fragmentType": "HeroMedia_data";
};
export type HeroMedia_data$key = {
  readonly " $data"?: HeroMedia_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeroMedia_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mediaType",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "aspectRatio",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnailUrl",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeroMedia_data",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PrimaryDropsBannerImage_data"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/)
      ],
      "type": "ImageMediaType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "videoUrl",
          "storageKey": null
        }
      ],
      "type": "VideoMediaType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "playbackId",
          "storageKey": null
        }
      ],
      "type": "MuxVideoMediaType",
      "abstractKey": null
    }
  ],
  "type": "MediaType",
  "abstractKey": "__isMediaType"
};
})();

(node as any).hash = "6e2898574bf2e3729bc7e84165d9ad77";

export default node;
