/**
 * @generated SignedSource<<52c5d9c04e4c2c949221521d25a9e18f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Config = "AFFILIATE" | "AFFILIATE_BLACKLISTED" | "AFFILIATE_REQUESTED" | "EMPLOYEE" | "MODERATOR" | "PARTNER" | "STAFF" | "VERIFIED" | "%future added value";
export type DropStageStageType = "PRESALE" | "PUBLIC" | "%future added value";
export type VerificationStatus = "MINTABLE" | "SAFELISTED" | "UNAPPROVED" | "VERIFIED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Banner_data$data = {
  readonly dropv2: {
    readonly landingPageReady: boolean;
    readonly name: string;
    readonly stages: ReadonlyArray<{
      readonly __typename: "DropStage721PublicLinearPricingType";
      readonly mintOptions?: ReadonlyArray<{
        readonly priceType: {
          readonly symbol: string;
          readonly unit: string;
        };
      }>;
      readonly priceType?: {
        readonly symbol: string;
        readonly unit: string;
      };
      readonly stageType: DropStageStageType;
    }>;
    readonly totalItemCount?: number;
  } | null;
  readonly externalUrl: string | null;
  readonly metadata: {
    readonly custom: {
      readonly hideProgressBar: boolean | null;
      readonly isMockDrop: boolean | null;
      readonly overrideName: string | null;
    } | null;
    readonly hero: {
      readonly partnerLogoUrl: string;
      readonly " $fragmentSpreads": FragmentRefs<"PrimaryDropHeroMedia_data">;
    } | null;
  } | null;
  readonly owner: {
    readonly config: Config | null;
    readonly displayName: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"AccountLink_data">;
  } | null;
  readonly slug: string;
  readonly verificationStatus: VerificationStatus;
  readonly " $fragmentSpreads": FragmentRefs<"HeroStageText_data" | "collection_url" | "useDropState_data" | "useShouldShowReminder_data">;
  readonly " $fragmentType": "Banner_data";
};
export type Banner_data$key = {
  readonly " $data"?: Banner_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"Banner_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalItemCount",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "PriceType",
  "kind": "LinkedField",
  "name": "priceType",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "symbol",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "mintOptions",
    "plural": true,
    "selections": [
      (v3/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Banner_data",
  "selections": [
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeroStageText_data"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "collection_url",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCategory",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "externalUrl",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useDropState_data"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionMetadataType",
      "kind": "LinkedField",
      "name": "metadata",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomMetadataType",
          "kind": "LinkedField",
          "name": "custom",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isMockDrop",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hideProgressBar",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "overrideName",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HeroMetadataType",
          "kind": "LinkedField",
          "name": "hero",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PrimaryDropHeroMedia_data"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "partnerLogoUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useShouldShowReminder_data"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "dropv2",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "landingPageReady",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "Drop721LimitedEditionType",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "Drop1155LimitedEditionType",
          "abstractKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "stages",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "stageType",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v4/*: any*/),
              "type": "DropStage721PublicLinearPricingType",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v4/*: any*/),
              "type": "DropStage721PresaleLinearPricingType",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v5/*: any*/),
              "type": "DropStage1155PublicLinearPricingType",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v5/*: any*/),
              "type": "DropStage1155PresaleLinearPricingType",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "verificationStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountType",
      "kind": "LinkedField",
      "name": "owner",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "config",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AccountLink_data"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};
})();

(node as any).hash = "5fee06854956943e131105e89c1c1600";

export default node;
